import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Form from 'src/components/Form';
import { saveDataToStorage } from 'src/utils';
import { pepApiRequest, RequestMethod } from 'src/services';
import { Option } from 'src/components/SelectOption';
import { useUserRoles } from 'src/auth';
import { useSolutionUserRoles } from '../../hooks';
import {
  getEditSolutionFormFields,
  getIndustrialSolutionFormFields,
  GetNewConsumerEditSolutionFormFields,
} from '../formFields';
import {
  SolutionType,
  SolutionDetailsItem,
  SolutionConsumerFeatures,
  SolutionIndustrialFeatures,
  SolutionSubscriptionType,
  SubscriptionType,
  SolutionFileSubscriptionType,
} from '../../types';
import { API_SOLUTION_CLIENTIDS } from '../../constants';

const style = {
  width: ({ spacing }) => spacing(55),
};

type Props = {
  item?: SolutionDetailsItem;
  onCancel: () => void;
  onSubmit: (values: Record<string, any>) => void;
  confirmBtnText?: string;
  disableCacheRetention?: boolean;
};

function EditSolutionForm({
  item,
  onCancel,
  onSubmit,
  confirmBtnText = 'Create',
  disableCacheRetention = false,
}: Props) {
  const { solutionType } = useParams();
  const [isMenderEnabled, setMenderEnabled] = useState<boolean | undefined>(
    item?.solutionConsumerFeatures?.includes(
      SolutionConsumerFeatures.IsMenderEnabled
    )
  );
  const { isPlatformEngineer } = useUserRoles();
  const [createSmsAccount, setCreateSmsAccount] = useState<boolean>(false);
  const isDataIngestionEnabled = item?.features?.dataIngestion;
  const isTelemetryStreamProcessingtype =
    item?.features?.telemetryStreamProcessingSetup?.isTelemetryStreamProcessing;
  const isSubscriptionServicetype =
    item?.features?.fileSubscriptionServiceSetup?.fileSubscriptionService ||
    item?.features?.isMessageSubscriptionService;
  const isFileSubscriptionServicetype =
    item?.features?.fileSubscriptionServiceSetup?.subscriptionType ===
      SolutionFileSubscriptionType.EDAPStorageAccount ||
    item?.features?.fileSubscriptionServiceSetup?.subscriptionType ===
      SolutionFileSubscriptionType.PEPsenseStorageAccount;
  const [isDataIngested, setDataIngested] = useState<boolean>(false);
  const [isReleasePrefix, setisReleasePrefix] = useState<any>(
    item?.features?.menderSetup?.menderEnabled
  );
  const [releasePrefix, setReleasePrefix] = useState<any>(
    item?.features?.menderSetup?.releasePrefix
  );

  const [IsTelemetryStreamProcessing, setTelemetryStreamProcessing] =
    useState<boolean>(false);
  const [subscriptionType, setSubscriptionType] = useState(false);
  const [isSubscriptionService, setSubscriptionService] = useState(false);
  const [cachePeriod, setCachePeriod] = useState<number>(30);
  const [retentionPeriod, setRetentionPeriod] = useState<number>(30);
  const [disableCacheLimit, setDisableCacheLimit] = useState<boolean>(false);
  const [disableRetentionLimit, setDisableRetentionLimit] =
    useState<boolean>(false);
  const [options, setOptions] = useState<Option[]>([]);
  const SOLUTION_FEATURES_CONSUMER = [
    {
      value: SolutionConsumerFeatures.IsDataIngested,
      label: 'Data Ingestion in PEPSense',
      disabled: isDataIngestionEnabled,
      tooltipInfo:
        'Data from IoT devices will be stored in PEPsense time series database',
    },
    {
      value: SolutionConsumerFeatures.IsFileSubscriptionService,
      label:
        solutionType === SolutionType.ioTH
          ? 'Device file upload service'
          : 'File subscription service',
      disabled: item?.solutionConsumerFeatures?.includes(
        SolutionConsumerFeatures.IsFileSubscriptionService
      ),
      tooltipInfo:
        'Helps to send files from devices to subscribed storage account',
    },
    {
      value: SolutionConsumerFeatures.IsSmsAccountCreation,
      label: 'SMS notification',
      disabled: item?.solutionConsumerFeatures?.includes(
        SolutionConsumerFeatures.IsSmsAccountCreation
      ),
      tooltipInfo: 'Helps to enable notifications via SMS',
    },
    {
      value: SolutionConsumerFeatures.IsMenderEnabled,
      label: 'Mender',
      disabled: item?.solutionConsumerFeatures?.includes(
        SolutionConsumerFeatures.IsMenderEnabled
      ),
      tooltipInfo: 'This feature will help to create devices in mender',
    },
  ];

  const SOLUTION_FEATURES_INDUSTRIAL = [
    {
      value: SolutionIndustrialFeatures.IsDataIngested,
      label: 'Data Ingestion in PEPSense',
      disabled: isDataIngestionEnabled,
      tooltipInfo:
        'Data from IoT devices will be stored in PEPsense time series database',
    },
    {
      value: SolutionIndustrialFeatures.IsSubscriptionService,
      label: 'Subscription service',
      disabled: item?.solutionIndustrialFeatures?.includes(
        SolutionIndustrialFeatures.IsSubscriptionService
      ),
      tooltipInfo:
        'Helps to create subscription to receive messages or send files to specified destinations',
    },
    {
      value: SolutionIndustrialFeatures.IsTelemetryStreamProcessing,
      label: 'Telemetry stream processing',
      disabled: item?.solutionIndustrialFeatures?.includes(
        SolutionIndustrialFeatures.IsTelemetryStreamProcessing
      ),
      tooltipInfo:
        'Helps to choose the type of service for streaming the telemetry data',
    },
    {
      value: SolutionIndustrialFeatures.IsSmsAccountCreation,
      label: 'SMS notification',
      disabled: item?.solutionIndustrialFeatures?.includes(
        SolutionIndustrialFeatures.IsSmsAccountCreation
      ),
      tooltipInfo: 'Helps to enable notifications via SMS',
    },
    {
      value: SolutionIndustrialFeatures.IsAlarmEventProcessing,
      label: 'Alarm/events stream processing',
      disabled: item?.solutionIndustrialFeatures?.includes(
        SolutionIndustrialFeatures.IsAlarmEventProcessing
      ),
      tooltipInfo:
        'Helps user to subscribe to receive the alarm and events data',
    },
  ];

  const SUBSCIPTION_TYPE_OPTIONS = [
    {
      value: SolutionSubscriptionType.Messages,
      label: 'Messages',
      disabled: item?.subscriptionType?.includes(SubscriptionType.Messages),
    },
    {
      value: SolutionSubscriptionType.Files,
      label: 'Files',
      disabled: item?.subscriptionType?.includes(SubscriptionType.Files),
    },
  ];

  const handleMenderEnable = ({ target }) => {
    setMenderEnabled(target.checked);
  };
  const handleSmsNotifyEnable = ({ target }) => {
    setCreateSmsAccount(target.checked);
  };
  const handleCacheLimit = ({ target }) => {
    setCachePeriod(target.value);
  };
  const handleRetentionLimit = ({ target }) => {
    setRetentionPeriod(target.value);
  };

  const handleUnlimitedCache = (e) => {
    if (e.target.value.length) {
      setCachePeriod(cachePeriod);
      setDisableCacheLimit(true);
    } else {
      setCachePeriod(Number(item?.CachePeriod) || cachePeriod);
      setDisableCacheLimit(false);
    }
  };
  const handleUnlimitedRetention = (e) => {
    if (e.target.value.length) {
      setRetentionPeriod(retentionPeriod);
      setDisableRetentionLimit(true);
    } else {
      setRetentionPeriod(Number(item?.RetentionPeriod) || retentionPeriod);
      setDisableRetentionLimit(false);
    }
  };
  const handleReleasePrefix = ({ target }) => {
    setReleasePrefix(target.value);
  };

  const handleConsumerFeatures = (e) => {
    const { value } = e.target;
    setisReleasePrefix(value.includes(SolutionConsumerFeatures.ReleasePrefix));
    setDataIngested(value.includes(SolutionConsumerFeatures.IsDataIngested));
    setMenderEnabled(value.includes(SolutionConsumerFeatures.IsMenderEnabled));
  };

  const handleIndustrialFeatures = (e) => {
    const { value } = e.target;
    setDataIngested(value.includes(SolutionIndustrialFeatures.IsDataIngested));
    setSubscriptionService(
      value.includes(SolutionIndustrialFeatures.IsSubscriptionService)
    );
    setCreateSmsAccount(
      value.includes(SolutionIndustrialFeatures.IsSmsAccountCreation)
    );
    setTelemetryStreamProcessing(
      value.includes(SolutionIndustrialFeatures.IsTelemetryStreamProcessing)
    );
  };

  const handleSubscriptionService = (e) => {
    const { value } = e.target;
    setSubscriptionType(value.includes(SubscriptionType.Files));
  };

  useEffect(() => {
    if (item?.CachePeriod !== (null || undefined)) {
      setCachePeriod(Number(item?.CachePeriod));
    }
  }, [item?.CachePeriod]);

  useEffect(() => {
    if (item?.RetentionPeriod !== (null || undefined)) {
      setRetentionPeriod(Number(item?.RetentionPeriod));
    }
  }, [item?.RetentionPeriod]);

  useEffect(() => {
    if (item && item.adxInstance === (null || undefined)) {
      setDisableCacheLimit(true);
      setDisableRetentionLimit(true);
    }
  }, [item]);

  useEffect(() => {
    if (item && item.UnlimitedCache) {
      setCachePeriod(cachePeriod);
      setDisableCacheLimit(true);
    }
    if (
      item?.features?.fileSubscriptionServiceSetup?.fileSubscriptionService ===
        true ||
      item?.features?.isMessageSubscriptionService === true
    ) {
      setSubscriptionService(true);
    }
  }, [item]);
  useEffect(() => {
    if (item && item.UnlimitedRetention) {
      setRetentionPeriod(retentionPeriod);
      setDisableRetentionLimit(true);
    }
  }, [item]);

  const solClientIds = item?.solutionOAuthClientId;
  const { isSolutionOwner } = useSolutionUserRoles(item?.id);
  const isValidUser = isSolutionOwner || isPlatformEngineer;
  useEffect(() => {
    pepApiRequest({
      method: RequestMethod.Get,
      url: API_SOLUTION_CLIENTIDS,
    }).then((clientIds) => {
      const clientIdoptions = clientIds.map((clientId) => {
        const option: Option = {
          label: clientId.id,
          value: clientId.id,
        };
        return option;
      });
      setOptions(clientIdoptions);
    });
    saveDataToStorage('solutionType', solutionType);
  }, []);

  const fetchFields = () => {
    if (solutionType === SolutionType.Consumer) {
      return getEditSolutionFormFields(
        solutionType,
        !!item,
        solClientIds,
        options,
        isValidUser,
        isMenderEnabled,
        createSmsAccount,
        handleMenderEnable,
        handleSmsNotifyEnable,
        cachePeriod,
        retentionPeriod,
        disableCacheLimit,
        disableRetentionLimit,
        handleCacheLimit,
        handleRetentionLimit,
        handleUnlimitedCache,
        handleUnlimitedRetention,
        disableCacheRetention,
        item?.kibanaSpace?.type,
        isDataIngested,
        isDataIngestionEnabled,
        handleConsumerFeatures,
        SOLUTION_FEATURES_CONSUMER
      );
    }
    if (solutionType === SolutionType.ioTH) {
      return GetNewConsumerEditSolutionFormFields(
        solutionType,
        !!item,
        solClientIds,
        options,
        isValidUser,
        isMenderEnabled,
        createSmsAccount,
        handleMenderEnable,
        handleSmsNotifyEnable,
        cachePeriod,
        retentionPeriod,
        disableCacheLimit,
        disableRetentionLimit,
        handleCacheLimit,
        handleRetentionLimit,
        handleUnlimitedCache,
        handleUnlimitedRetention,
        handleReleasePrefix,
        disableCacheRetention,
        item?.kibanaSpaceType,
        isDataIngested,
        isReleasePrefix,
        releasePrefix,
        isDataIngestionEnabled,
        handleConsumerFeatures,
        SOLUTION_FEATURES_CONSUMER
      );
    }
    return getIndustrialSolutionFormFields(
      solClientIds,
      options,
      isValidUser,
      createSmsAccount,
      handleSmsNotifyEnable,
      cachePeriod,
      retentionPeriod,
      disableCacheLimit,
      disableRetentionLimit,
      handleCacheLimit,
      handleRetentionLimit,
      handleUnlimitedCache,
      handleUnlimitedRetention,
      disableCacheRetention,
      item?.kibanaSpace?.type,
      isDataIngested,
      isDataIngestionEnabled,
      isSubscriptionService,
      isSubscriptionServicetype,
      subscriptionType,
      isFileSubscriptionServicetype,
      IsTelemetryStreamProcessing,
      isTelemetryStreamProcessingtype,
      handleIndustrialFeatures,
      handleSubscriptionService,
      SOLUTION_FEATURES_INDUSTRIAL,
      SUBSCIPTION_TYPE_OPTIONS
    );
  };
  return (
    <Form
      fields={fetchFields()}
      fieldProps={{ sx: style }}
      values={item}
      onSubmit={onSubmit}
      visibleValuesOnly
    >
      <Box textAlign="right" mt={3}>
        <Button onClick={onCancel} variant="outlined" sx={{ mr: 2 }}>
          Cancel
        </Button>
        <Button type="submit" variant="contained">
          {confirmBtnText}
        </Button>
      </Box>
    </Form>
  );
}

export default EditSolutionForm;
